@import '../../css/variables.scss';

.change-profile-photo-content {
    text-align: center;

    h2 {
        margin-bottom: 1em;
    }
}

.image-error {
    color: $error
}

.preview-loading-msg { 
    margin-top: 1rem;
    color: $gray-slate;
    font-weight: bold;
}