.sigtable, .imgtable, table {
    margin:0;
    padding:0;
    mso-table-lspace:0pt;
    mso-table-rspace:0pt;
    -webkit-text-size-adjust:100%;
    -ms-text-size-adjust:100%;
}
table td, table td p, table td a {
    border-collapse: collapse;
    border: 0;      
}
.sigtable td, table td, .sigtable td , table td , .sigtable td a, table td a {
    line-height: 11px;
}
.sigtable td a, table td a {
    color: #000000;
    text-decoration: none;
    font-weight:bold;
}
.sigtable .linetest, table .linetest {
    font-weight:bold;
    font-style: italic;
}
a:link, span.MsoHyperlink {
    mso-style-noshow:yes;
    mso-style-priority:99;
    mso-style-parent:"";
    color:black;
    text-decoration:none;
    text-underline:none;}
a:visited, span.MsoHyperlinkFollowed {
    mso-style-noshow:yes;
    mso-style-priority:99;
    color:#000000;
    mso-themecolor:followedhyperlink;
    text-decoration:none;
    text-underline:none;
}
.ExternalClass{width:100%; }
.ExternalClass, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {
    line-height: 100%;
}   
img{-ms-interpolation-mode:bicubic; }