@import 'variables.scss';

/* reset */ 
* {
  margin:0;padding:0;
  -mox-osx-font-smoothing: 'grayscale';
  -webkit-font-smoothing: 'antialiased';
  box-sizing: 'border-box';
}
img, a img { border: none}
ul {list-style-type:none}
a {outline: none; -moz-outline-style: none}


/* -------------------------------------------| links & type */
a:link, a:active, a:visited {text-decoration:none;border:none; color: $blue;}
a:hover { }
@import 'fonts.scss';

h1 {
  margin: 0 0 2em 0;
  font-weight: 500;
}
p {
  line-height: 1.25em;
  margin-bottom: 1em;
  a {
    font-weight: 500;
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
      color: $font-base-color;
    }
  }
}


/* -------------------------------------------| elements */
body {
  background: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  font-weight: 300;
  color: $font-base-color;
}

section {
  margin: 0 0 1em 0;
}

#global-header {}
#global-footer {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
  a {
    font-weight: 500;
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
      color: $font-base-color;
    }
  }
  span {
    margin: 0 20px;
  }
}

#body-content {
  padding: 1em 2em 2em 2em;
  height: auto;
}

.card {
  margin: 0 auto 1em auto;
  max-width: 1400px;
  article {
    padding: 2em 0;
  }
  &.gray {
    background: #f1f1f1;
    border-radius: 5px;
    max-width: unset !important;
    article {
      padding: 2em;
    }
  }
  &.border-bottom {
    border-bottom: solid 1px $gray-light;
  }
  &.narrow {
    max-width: 650px;
  }
}

/* ---
  Workaround for label/border overlap bug:
  https://github.com/mui-org/material-ui/issues/16833
*/
.MuiFormControl-root {
  label {
    background-color: #fff;
  }
}
.MuiInputBase-root, .MuiInputBase-root:focus, .MuiSelect-select, .MuiSelect-select:focus {
  background-color: #fff !important;
}
/* --- */

.footnote {
  margin-top: -2em;
  p {
    font-size: 12px;
    color: #666;
    code {
      color: #222;
    }
  }
}


/* -------------------------------------------| misc */
.clear { clear: both; }
.left { float: left; }
.right { float: right; }
.clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.nowrap {
  white-space: nowrap;
}

/* for buttons styled as links */
.buttonLink {
  text-decoration:none;
  border:none; 
  background-color:#ffffff;
  color: $blue;
  font-family: 'Roboto', sans-serif;
  font-size:14px;
  font-weight:300;
          }

.buttonLink:hover {  
  text-decoration:underline;
  cursor: pointer;
                  }

/* -------------------------------------------| modals */

.modal {  
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  z-index: 999;
  width: 90%;
  min-width: 400px;
  max-width: 600px;
  border: none;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.25);
  .close-modal {
    float: right;
    padding: 5px 5px 0 0;
    height: 20px;
    cursor: pointer;
    fill: $gray-slate;
  }

  .modal-content {
    border: none;
    padding: 1em 2em;
    h2 {
      font-size: 24px;
      text-align: center;
      font-weight: normal;
    }
  }
}

.backdrop {
  z-index: 999 !important;
  .MuiCircularProgress-svg {
    color: #fff;
  }
}

@media (max-width: 600px) {
  #body-content {
    padding: 1em 0.5em 2em 0.5em;
  }
}

@media (max-width: 480px) {
  #body-content {
    // padding: 1em 0.5em 2em 0.5em;
    padding: 1em;
    overflow: hidden;
  }
}

@media (max-width: 400px) {
  .modal {
    min-width: 400px;
    max-width: 600px;    
  }
}

.required-question {
  h4:after {
    content: ' *';
    color: $error;
  }
}

.question-group.group-3 {
  margin-top: 0;

  article {
    padding: 1em 0;
  }
}

@import 'global-overrides.scss';