@import '../../css/variables.scss';

.survey-dashboard {
  margin-bottom: 0;
  &:last-child {
    margin-top: -20px;
  }
  .button-back {
    float: right;
    margin-top: -50px;
    color: #fff !important;
    &:hover {
      color: $blue !important;
    }
  }
  .tableau-iframe {
    width: 100%;
    height: 80vh;
  }

}

@media (max-width: 450px) {

}