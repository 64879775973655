$font-base-color: rgb(34,34,34);
$font-menu-color-dark: rgb(74,74,74);
$font-base-size: 16px;

$blue: #4A90E2;
$gray-light: #cecece;
$gray: #9B9B9B;
$gray-slate-light: #5D6770;
$gray-slate: #4F575F;
$gray-slate-dark: #474E55;
$personal-highlight: #4CAF50;
$professional-highlight: #3F51B5;

$success: #64AB18;
$error: #E63D2F;
$info: #4A90E2;
$warning: #FFEB00;

$callout-banner-bg: #fff66c;

$personal-highlight: #4CAF50;
$professional-highlight: #3F51B5;

$workHours-highlight: #4CAF50;
$nonWorkHours-highlight: #E63D2F;

$border-color-light: rgba(255,255,255,0.25);

$border-radius: 5px;
$box-shadow: 0 2px 3px rgba(0,0,0,0.1);