@import '../../css/variables.scss';

.signature-generator-content {
    .MuiTextField-root {
        margin-bottom: 20px !important;
    } 

    @media (max-width: 430px) {
        h2 {
            font-size: 18px !important;
        }

        .MuiBox-root {
            margin-bottom: 20px !important;
        }

        .MuiInputBase-input {
            font-size: 12px;
            padding: 13px 14px !important;
        }

        #otherPhoneLabel, #pronounLabel {
            font-size: 14px;
        }

        #selectLabel, #selectPronouns {
            font-size: 14px !important;
            padding: 11px 14px !important;
        }

        .MuiInputLabel-outlined {
            transform: translate(10px, 13px) scale(1);

            &.MuiInputLabel-shrink {
                font-size: 1rem;
            }
        }

        .react-tel-input { 
            margin-bottom: 15px !important;

            .form-control {
                font-size: 12px;
                padding: 13px 14px 13px 58px;
            }
        }

        .otherPhone {
            margin-bottom: 15px !important;
        }

        .MuiTextField-root {
            margin-bottom: 15px !important;
        }
    }

    @media (max-width: 377px) {
        .MuiTab-wrapper {
            font-size: 0.7rem;
        }
    }

    @media (max-width: 340px) {
        h2 {
            font-size: 16px !important;
        }
    }
}

.otherPhone {
    margin-right: 20px !important;
    width: 168px;
}

.actions {
    text-align: center;
}

#signature-container {
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.copy-content {
    float: right;
    height: 18px !important;
    cursor: pointer;
}

.copy-content:hover {
    fill: #a0a0a0;
}

.special-label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    left: 8px !important;
}

.MuiTab-root {
    width: 33%;
}
