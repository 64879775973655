@import '../../css/variables.scss';

.profile-context-nav {
    background: #F1F1F1;
    border-radius: $border-radius;
    /* same box shadow as the top tab bar */
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    margin: 2em auto 0 auto;
    max-width: 240px;
    padding: 1em;
    position: sticky;
    text-align: left;
    top: 2em;

    h3 {
        border-bottom: 1px solid $gray-light;
        font-size: 18px;
        margin-bottom: 0.5em;
        padding-bottom: 0.5em;
    }

    ul {
        li {
            margin-bottom: 0.5em;
        }
    }

    a {
        &:hover {
            color: $gray-slate-dark;
            text-decoration: underline;
        }
    }
}