@import 'variables.scss';

.MuiButton-root {
  border-radius: 60px !important;
  box-shadow: none !important;
  font-weight: 400 !important;
}
.MuiInputBase-input {
  font-weight: 300 !important;
}

/* ---
  Workaround for label/border overlap bug:
  https://github.com/mui-org/material-ui/issues/16833
*/
.MuiFormControl-root {
  label {
    background-color: #fff;
  }
}
.MuiInputBase-root, .MuiInputBase-root:focus, .MuiSelect-select, .MuiSelect-select:focus {
  background-color: #fff !important;
}
/* --- */

.MuiButton-containedPrimary {
  background-color: $blue !important;
}

.MuiButton-outlinedPrimary {
  border-color: $blue !important;
  color: $blue !important;
}

.MuiButton-textPrimary {
  color: $blue !important;
  text-decoration: underline !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $blue !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $blue !important;
}


.MuiRadio-colorSecondary, .MuiCheckbox-colorSecondary {
  color: $gray !important;
}

.MuiRadio-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked {
  color: #222 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: $gray-light !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: $blue !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $blue !important;
}

.PrivateTabIndicator-colorPrimary-6 {
  background-color: $blue !important;
}

/* radio buttons */
.MuiIconButton-label {
  svg:last-child {
    color: $blue;
  }
}

.MuiTableCell-sizeSmall {
  white-space: nowrap;
}

// snackbar close button
.MuiSnackbarContent-action .MuiIconButton-label svg:last-child {
  color: white;
}