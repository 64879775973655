@import '../../css/variables.scss';

.aweCalloutBanner {
    background-color: $callout-banner-bg;
    padding: 10px;
    position: relative;
    margin: -1rem -2rem;
    text-align: right;

    .text {
        font-size: 14px;
        line-height: 1.5em;
    }

    .closeButton {
        padding: 0;
        margin-left: 10px;
    }
}